const en = {
	title: {
		home: 'Home',
	},
	nav: {
		desc: 'About',
		desc1: 'Vision',
		desc2: 'Service',
		desc3: 'Core Product',
		desc4: 'Privacy Policy',
		desc5: 'choose language',
		desc6: '',
		desc7: '',
		desc8: '',
		desc9: '',
		desc10: '',
	},
	home: {
		desc: 'SkyDAO Trust is headquartered in Hong Kong and operates as a global Web3 trust platform, leveraging trust legal systems worldwide.',
		desc1: 'SkyDAO Trust is a hybrid finance (HyFi) asset management platform built collaboratively by experienced financial professionals and a team of blockchain Web3 pioneers. It incorporates cutting-edge technologies such as blockchain and AI algorithms to create a sophisticated platform. At SkyDAO, users only need to have a trust super account to easily invest in major fiat and virtual financial assets worldwide through smart contract wallets. They can also enjoy comprehensive wealth management services, including asset allocation, risk tracking, and portfolio management, all in one place.',
		desc2: 'Robust institutional framework.',
		desc3: "Through the common law trust structure, we can flexibly adapt to the compliance requirements of different countries and regions. With the support of Hong Kong's open policy towards virtual financial transactions, users from different legal jurisdictions can seamlessly enjoy global asset allocation.",
		desc4: 'Solid underlying technology.',
		desc5: 'Combining Web2 and Web3, empowered by big data and AI algorithms, and adopting a HyFi operational model that integrates both Defi and Cefi in the field of cryptocurrency finance, maximizes the advantages of cryptocurrency finance, ensuring investment security, transparency, and fairness.',
		desc6: 'Wide-ranging asset coverage',
		desc7: 'Covering a wide range of asset classes globally, including stocks, futures, options, bonds, funds, insurance, cryptocurrencies, and more, investment users can easily access global assets and flexibly manage asset allocation through a single super account, enabling comprehensive and diversified portfolio management.',
		desc8: 'Enrich asset allocation',
		desc9: 'Enhance investment decision-making efficiency',
		desc10: 'Facilitate asset liquidity',
		desc11: 'Provide peace of mind to investment users',
		desc12: 'Promote fair asset trading',
		desc13: 'Lead global asset security',
		desc14: '6 major service sectors',
		desc15: 'Providing comprehensive trust services for global users',
		desc16: 'Family Office Trust',
		desc17: 'Leveraging the resources of family offices in Dubai, London, Italy, Canada, and Hong Kong to provide operational templates for family offices',
		desc18: 'Stablecoin Asset Management Trust',
		desc19: 'Managing algorithmic stablecoin reserves to provide the ultimate security for safe investments',
		desc20: 'Third-party digital wallet payment channel service',
		desc21: 'Supports direct P2P transactions between users, where assets are only held by users themselves, giving them full control.',
		desc22: 'Third-party institutional asset trust management service',
		desc23: 'Strategic partner of SkyDAO Trust Web3, offering investors more investment portfolios based on Defi-Cefi.',
		desc24: 'Credit card asset trust.',
		desc25: 'Support credit card transactions with fiat currency, truly bridging the gap between crypto finance and traditional finance.',
		desc26: 'XTM Global Asset Trust Management Platform',
		desc27: 'Official financial platform of SkyDAO Trust',
		desc28: 'countries',
		desc29: 'exchanges',
		desc30: 'trading pairs',
		desc31: 'Trading system',
		desc32: 'Traditional finance TradFi',
		desc33: 'On-chain decentralized finance DeFi',
		desc34: 'Core products',
		desc35: 'SkyDAO hybrid financial asset trust management platform',
		desc36: 'HyFi all-in-one management',
		desc37: 'TradiFi',
		desc38: 'Integration of Traditional Financial Asset Links',
		desc39: 'Traditional Financial Assets',
		desc40: 'Traditional Financial Instruments',
		desc41: 'Crypto',
		desc42: 'Decentralized Global Digital Trading',
		desc43: 'Digital Financial Assets',
		desc44: 'Decentralized Financial Technology',
		desc45: 'Decentralized',
		desc46: 'Ensuring the Security of On-Chain Assets',
		desc47: 'Security',
		desc48: 'Users maintain security and anonymity by using their own smart contract wallets on the Ethereum blockchain. Users have full control over their assets within the platform, as they possess their own private keys.',
		desc49: 'Transparency',
		desc50: "The user's smart contract account is clearly visible on the blockchain (such as the Etherscan.io website). This is different from centralized exchanges where traders' deposits are stored in one or a few large wallets controlled by the exchange.",
		desc51: 'Security',
		desc52: "Users' funds are fully transparent, ensuring the ability to fulfill each transaction and settling on the blockchain, with settled funds remaining in the user's account.",
		desc53: 'SkyDAO Virtual Credit Card',
		desc54: 'With one card in hand, enjoy seamless integration between Fiat and Crypto.',
		desc55: 'Flexible and Convenient',
		desc56: 'Flexible use of diverse assets',
		desc57: 'Convenient repayment on the platform',
		desc58: 'Global acceptance',
		desc59: 'Withdraw Cash Anytime, Anywhere',
		desc60: 'Flexibly Utilize a Variety of Assets on the Platform',
		desc61: 'Safe and Secure',
		desc62: '2FA Authentication with Biometric Password Support',
		desc63: 'Multiple Authentications Ensure Card Security',
		desc64: 'Investors easily access and navigate global financial markets.',
		desc65: 'Management of On-Chain Assets',
		desc66: '',
		desc67: '',
		desc68: '',
		desc69: '',
	},
	download: {
		desc: 'Embark on Your Journey to Digital Financial Freedom',
		desc1: 'A Dual Financial Experience of Centralization and Decentralization',
		desc2: 'Manage Your Assets Anytime, Anywhere',
		desc3: 'Download the SKYDAO App',
		desc4: 'Trust Wallet provides users with a seamless and secure way to manage digital assets and access financial services. Powered by a professional centralized institution, it combines cutting-edge technology with compliance frameworks, enabling users to efficiently manage their funds, utilize credit card services, and perform cross-border payments with ease.',
		desc5: 'Credit Card Services',
		desc6: 'Supports global VISA payment channels, offering digital asset spending and withdrawal services.',
		desc7: 'Asset Custody',
		desc8: 'Professional institutional-grade custody services to ensure the security of your funds.',
		desc9: 'Quick Payment',
		desc10: 'Bind a bank card to enable fiat currency deposits and withdrawals.',
		desc11: 'Security',
		desc12: 'A professional team operates the system, utilizing advanced technological methods to ensure the security of funds.',
		desc13: 'Cryptocurrencies are experiencing rapid growth, and many individuals have achieved impressive returns through crypto investments. However, there is still a missing bridge connecting cryptocurrencies to our daily lives. The SKYDAO Card addresses this issue, enabling cryptocurrencies to deliver greater value in our everyday activities.',
		desc14: 'The SKYDAO Card allows you to quickly complete online shopping, offline purchases, withdrawals, and transfers. With a SKYDAO Card, you can embark on a convenient financial journey in the world of cryptocurrencies.',
		desc15: 'SKYDAO Bud Card',
		desc16: 'SKYDAO Black Card',
		desc17: 'SKYDAO Bright Card',
		desc18: 'Web3 Wallet',
		desc19: "The SKYDAO Web3 Wallet is a decentralized multi-chain wallet that is simple, secure, and convenient. With just one click, you can create a wallet, eliminating the need for complex procedures. It offers token swaps, asset appreciation, and participation in the decentralized finance (DeFi) ecosystem. Whether it's staking, lending, trading, or investing, the Web3 Wallet provides a seamless user experience and a wide range of functional options.",
		desc20: 'Non-custodial wallet',
		desc21: 'The private key is in your control, allowing you to have complete control over your assets.',
		desc22: 'Multi-chain support',
		desc23: 'Supports mainstream public chains such as BTC, ETH, BSC, and more, allowing for seamless cross-chain transactions.',
		desc24: 'One-click access to DeFi applications, enabling participation in staking, lending, yield farming, and other operations.',
		desc25: 'Privacy protection',
		desc26: 'Complete anonymity, with no identity verification required for use.',
		desc27: 'Why choose SKYDAO?',
		desc28: 'Credit Card Services',
		desc29: 'Global payments at your fingertips',
		desc30: 'Easily bind a virtual credit card using cryptocurrency assets.',
		desc31: 'Real-time updates of spending records, supporting the global VISA payment network.',
		desc32: 'Asset Custody',
		desc33: 'Security is our top priority',
		desc34: 'Institutional-grade security custody, offering collateral and insurance services.',
		desc35: 'Strict multi-signature and cold storage technology.',
		desc36: 'Decentralized freedom',
		desc37: 'Take control of your assets',
		desc38: 'No need to trust third parties; the private key is entirely controlled by the user.',
		desc39: 'Instant access to the global DeFi ecosystem.',
		desc40: 'Coming soon',
		desc41: 'APP Download',
		desc42: '',
		desc43: '',
		desc44: '',
		desc45: '',
		desc46: '',
		desc47: '',
		desc48: '',
		desc49: '',
	},
	policy: {
		desc: '',
		desc1: '',
		desc2: '',
		desc3: '',
		desc4: '',
		desc5: '',
		desc6: '',
		desc7: '',
		desc8: '',
		desc9: '',
		desc10: '',
		desc11: '',
		desc12: '',
		desc13: '',
		desc14: '',
		desc15: '',
		desc16: '',
		desc17: '',
		desc18: '',
		desc19: '',
		desc20: '',
		desc21: '',
		desc22: '',
		desc23: '',
		desc24: '',
		desc25: '',
		desc26: '',
		desc27: '',
		desc28: '',
		desc29: '',
		desc30: '',
		desc31: '',
		desc32: '',
		desc33: '',
		desc34: '',
		desc35: '',
		desc36: '',
		desc37: '',
		desc38: '',
		desc39: '',
		desc40: '',
		desc41: '',
		desc42: '',
		desc43: '',
		desc44: '',
		desc45: '',
		desc46: '',
		desc47: '',
		desc48: '',
		desc49: '',
	}
};
export default en;