import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate"

export default createStore({
  // plugins: [createPersistedState({
  //   storage: window.sessionStorage
  // })],
  state: {
    isNavShow: false,
    isMobile: false,
    activeIndex: 0,
    lang: 'en_US',
    androidApkLink: 'https://scrypto.s3.ap-northeast-1.amazonaws.com/download/android/skydao.apk',
    testFlightLink: 'https://testflight.apple.com/join/lfPm8pM0',
  },
  mutations: {
    SETISMOBILE(state, val) {
      state.isMobile = val
    },
    SETNAVSHOW(state, val) {
      state.isNavShow = val
    },
    SETACTIVEINDEX(state, val) {
      state.activeIndex = val
    },
    SETLANG(state, val) {
      state.lang = val
    },
  },
  actions: {
  },
  getters: {
  },
  modules: {
  }
})
