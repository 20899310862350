<template>
  <div id="Header" class="header flexja" :class="[isMobile ? 'header-mobile' : '']"
    :style="{background: isScrollIng?'#fff':'transparent'}">
    <div class="logo flex-center" @click="$router.push('/home')">
      <img src="../public/img/logo.png" alt="">
      <img src="../public/img/logo-name.png" alt="">
    </div>
    <div class="nav-list flexja" v-if="title == 'home' && !isMobile">
      <div class="nav-item" :class="{'on':active == 1}" @click="handleGo(1)">{{ $t('nav.desc') }}</div>
      <div class="nav-item" :class="{'on':active == 2}" @click="handleGo(2)">{{ $t('nav.desc1') }}</div>
      <div class="nav-item" :class="{'on':active == 3}" @click="handleGo(3)">{{ $t('nav.desc2') }}</div>
      <div class="nav-item" :class="{'on':active == 4}" @click="handleGo(4)">{{ $t('nav.desc3') }}</div>
    </div>
    <div class="flex_right">
      <div class="nav_download" @click="handleDownload()">{{ $t('download.desc41') }}</div>
      <div class="ex-lang flexja" v-if="!isMobile && title !== 'policy'">
        <div class="lang" @click="handlerLang('en_US')">
          <img v-if="langName == 'en_US'" src="../public/img/en-on.png" alt="">
          <img v-else src="../public/img/en.png" alt="">
        </div>
        <div class="lang" @click="handlerLang('zh_TW')">
          <img v-if="langName == 'zh_TW'" src="../public/img/cn-on.png" alt="">
          <img v-else src="../public/img/cn.png" alt="">
        </div>
      </div>
      <div class="ex-bg" @click="exchangeShow" v-if="isMobile">
        <img src="../public/img/h5/ex-bt.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import store from '../store'
export default {
  inject: ["reload"],
  props: {
    isScrollIng: Boolean,
    active: Number,
    title: String,
  },
  data() {
    return {
      langName: 'en_US',
    }
  },
  computed: {
    ...mapState(['isNavShow', 'isMobile']),
  },
  mounted() {
    if (localStorage.getItem('language')) {
      this.langName = localStorage.getItem('language')
    }
  },
  methods: {
    exchangeShow() {
      this.$store.commit('SETNAVSHOW',!this.isNavShow)
    },
    handleDownload() {
      this.$router.push('/download')
    },
    handleGo(num) {
      this.$emit('goCore',num)
    },
    handlerLang(val) {
      store.commit('SETLANG',val)
      localStorage.setItem('language', val)
      this.langName = val
      this.$i18n.locale = val
      // this.reload()
    },
  }
}
</script>
<style lang="less" scoped>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.nav_download {
  cursor: pointer;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #949191;
  line-height: 34px;
  padding: 0 28px;
}
.flexja {
  display: flex;
  align-items: center;
}
img {
  display: block;
}
.header {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-between;
  padding: 0 1.39rem;
  width: 100%;
  height: 1.4rem;
  margin-bottom: 0.52rem;

  .logo {
    cursor: pointer;
    img {
      width: 0.3876rem;
      height: 0.3876rem;

      &:nth-child(2) {
        margin-left: 0.06rem;
        width: 1.148rem;
        height: 0.226rem;
      }
    }
  }

  .nav-list {
    .nav-item {
      margin: 0 0.26rem;
      font-family: Arboria;
      font-size: 0.25rem;
      font-weight: 500;
      line-height: 0.3rem;
      letter-spacing: 0em;
      text-align: center;
      color: #9F9F9F;
      cursor: pointer;

      &:hover {
        color: #2D2D2D;
      }
    }
  }

  .ex-lang {
    .lang {
      cursor: pointer;

      img {
        width: auto;
        height: 0.21rem;
      }

      &:first-child {
        margin-right: 0.11rem;
      }
    }
  }
}

@media screen and (max-width: 1400px) {}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 1000px) {
  .nav_download {
    border-radius: 20px;
    line-height: 20px;
    padding: 0 15px;
  }
}

.header-mobile {
  padding: 0 0.39rem;
  height: 0.8rem;
  .logo {
    img {
      width: 0.2424rem;
      height: 0.2424rem;

      &:nth-child(2) {
        margin-left: 0.04rem;
        width: 0.7175rem;
        height: 0.1412rem;
      }
    }
  }
  .ex-bg {
    img {
      width: 0.2rem;
      height: auto;
    }
  }
}
</style>
